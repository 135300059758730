<template>
  <v-container>
    <div class="d-flex align-center mb-4">
      <h1 class="text-h5">
        <span class="work-sans">Logs do sistema</span>
      </h1>

      <v-spacer />

      <v-chip color="surface" link>
        <span> Exportar </span>
        <v-icon right>mdi-download</v-icon>
      </v-chip>
    </div>

    <loader-content v-if="loading" />

    <section v-else>
      <FilterInput
        class="mb-4"
        :dataFilters="require('@/assets/filters/logs.json')"
      />

      <v-card class="shadow rounded-lg mb-6" color="backgorund">
        <v-data-table
          v-model="selectedItems"
          class="transparent"
          item-key="depositoID"
          :headers="_headers"
          :items="logs"
          hide-default-footer
          disable-pagination
          disable-sort
          show-select
        >
          <!-- user -->
          <template v-slot:[`item.nome`]="{ item }">
            <v-chip color="primary" @click="openUser(item.userID)">
              <v-icon small left>mdi-account</v-icon>

              <span class="cut">{{ item.nome }}</span>
            </v-chip>
          </template>

          <!-- value -->
          <template v-slot:[`item.valor`]="{ item }">
            R$ {{ moneyMask(item.valor) }}
          </template>

          <!-- final Value -->
          <template v-slot:[`item.finalValue`]="{ item }">
            <div v-if="item.DadosSaque.taxas" class="d-flex flex-column">
              <span>
                R$
                {{
                  moneyMask(
                    Number(item.valor) - Number(item.DadosSaque.taxas.taxaSaque)
                  )
                }}
              </span>

              <span class="subtext--text text-caption">
                Taxa: {{ moneyMask(item.DadosSaque.taxas.taxaSaque) }}
              </span>
            </div>

            <div v-else>R$ {{ moneyMask(item.valor) }}</div>
          </template>

          <template v-slot:[`item.dados`]="{ item }">
            <div v-if="item.tipo === '0'" class="d-flex flex-column">
              <span
                >{{ item.DadosSaque.agencia }} /
                {{ item.DadosSaque.conta }}</span
              >
              <span class="subtext--text text-caption cut">
                {{ item.DadosSaque.banco }}
              </span>
            </div>

            <div v-else class="d-flex flex-column">
              <span>{{ item.DadosSaque.chave }}</span>
              <span class="subtext--text text-caption">
                {{ item.DadosSaque.tipoChave }}
              </span>
            </div>
          </template>

          <!-- status -->
          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="handleStatus(item.status).color" label small dark>
              {{ handleStatus(item.status).label }}
            </v-chip>
          </template>

          <!-- actions -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn class="rounded-lg" icon @click="handleManage(item)">
              <v-icon>mdi-open-in-app</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>

      <!-- pagination -->
      <v-pagination
        v-model="pagination.page"
        color="primary"
        :length="pagination.pageCount"
        :total-visible="10"
        @input="getData()"
      />
    </section>
  </v-container>
</template>

<script>
import { request } from "@/services";
import { handlePagination } from "@/utils";
import FilterInput from "@/components/filters/FilterInput";
const moment = require("moment");

export default {
  data() {
    return {
      loading: true,
      selectedItems: [],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        order: 0,
      },
      logs: [],
    };
  },

  components: {
    FilterInput,
  },

  beforeMount() {
    this.getData();
  },

  watch: {
    ["$route.query"](value) {
      this.handleQuery(value);
    },
  },

  computed: {
    _headers() {
      return [
        { text: "ID", value: "idLog" },
        { text: "Admin", value: "loginAdmin" },
        { text: "Descrição", value: "descricao" },
        { text: "Data ", value: "data" },
      ];
    },

    fileName() {
      return `earthguard_losg_export_${moment().format("YYYY-MM-DD")}.csv`;
    },
  },

  methods: {
    async getData(filter = {}) {
      try {
        const payload = {
          method: "listLogsAdmin",
          page: this.pagination.page - 1,
          ...filter,
        };

        await request(payload).then((res) => {
          this.logs = res.logs;
          this.pagination.pageCount = handlePagination(res.total);
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    handleQuery(value) {
      this.loading = true;
      this.pagination.page = 1;

      const query = {
        nickFilter: value.nickFilter || undefined,
        dataInicial: value.period ? value.period.split(",")[0] : undefined,
        dataFinal: value.period ? value.period.split(",")[1] : undefined,
      };

      this.getData(query);
    },
  },
};
</script>

<style lang="scss" scoped>
.cut {
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
